
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { getIcons } from './IconStore';
import { icon } from './@types/icon.interface';

@Options({
	name: 'DaIcon',
})
export default class DaIcon extends Vue {
	//----------------------- 🤍 Props 🤍 -----------------------//
	@Prop({ default: 'dasd--home' }) name?: string;

	//----------------------- 🤍 Getters 🤍 -----------------------//
	get iconData(): icon {
		return getIcons(this.name);
	}
}
